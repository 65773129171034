<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-top justify-left>
        <v-flex xs12 sm2>
          <v-list dense style="background: transparent">
            <template v-for="(item, i) in items">
              <v-layout v-if="item.heading && checkRoute(item)" :key="i" row align-center>
                <v-flex xs12>
                  <v-subheader v-if="item.heading">
                    {{ item.heading }}
                  </v-subheader>
                </v-flex>
              </v-layout>
              <v-divider v-else-if="item.divider" :key="i" dark class="my-3"></v-divider>
              <v-list-tile v-else-if="checkRoute(item)" :key="i" @click="goRoute(item.route)">
                <v-list-tile-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title class="grey--text">
                    {{ item.text }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-flex>
        <v-flex xs12 sm10 class="px-3">
          <router-view :config="config" :user="user" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { CONFIG } from '../../config'

export default {
  name: 'Setting',
  data() {
    return {
      config: [],
      dateContest: null,
      activeContest: 0,
      items: [
        { heading: 'Configuracion' },
        { icon: 'settings', text: 'General', route: 'settingsGeneral' },
        { icon: 'list', text: 'Archivos Publicos', route: 'settingsFiles' },
        // { icon: 'settings', text: 'Web', route: 'settingsGlobal' },
        { heading: 'WhatsApp' },
        { icon: 'settings', text: 'Palabras Predefinidas', route: 'settingsMsjWsp' },
        { icon: 'inbox', text: 'Envios Masivos', route: 'settingsEnvioMasivo' },
        { heading: 'Blog' },
        { icon: 'list', text: 'Posts', route: 'settingsBlogPosts' },
        { icon: 'list', text: 'Categorias', route: 'settingsBlogCategory' },
        /*
        { heading: 'Correos electronicos' },
        {
          icon: 'inbox',
          text: 'Fecha Especial',
          route: 'settingsEmailSpecial'
        },
        */
        { heading: 'Web' },
        { icon: 'list', text: 'Configuracion web', route: 'settingsConfigWeb' },
        { icon: 'list', text: 'Paginas Web/Mobile', route: 'settingsViews' },
        { icon: 'list', text: 'Links de intereses', route: 'settingsLinks' },
        { icon: 'list', text: 'CMS', route: 'settingsCMS' },
        { icon: 'list', text: 'Preguntas frecuentes', route: 'settingsFaq' },
        { icon: 'list', text: 'Categorias', route: 'settingsCategories' },
        { icon: 'list', text: 'Direccones predefinidas', route: 'settingsAddress' },
        { icon: 'list', text: 'Notificaciones Push', route: 'settingsPush' },
        { heading: 'Informes' },
        // { icon: 'settings', text: 'Encuestas', route: 'statisticsPolls' },
        {
          icon: 'settings',
          text: 'Fechas importantes cupones',
          route: 'statisticsDateImportant'
        },
        {
          icon: 'settings',
          text: 'Cupones de descuento',
          route: 'discountCoupons'
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getConf()
  },
  methods: {
    async getConf() {
      try {
        const res = await this.$http.get(CONFIG, {
          params: {
            shopID: this.user.shopID
          }
        })
        this.config = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    checkRoute(item) {
      if (item.route === 'settingsWhatsApp' && (this.user.role.id !== 1 || !this.user.chat)) {
        return false
      }
      if (item.route === 'settingsEmailSpecial' && (this.user.role.id !== 1 || !this.user.chat)) {
        return false
      }
      if (item.route === 'settingsViews' && (this.user.role.id !== 1 || !this.user.chat)) {
        return false
      }
      if (
        (item.heading === 'Web' || item.heading === 'Correos electronicos') &&
        (this.user.role.id !== 1 || !this.user.chat)
      ) {
        return false
      }
      return true
    },
    goRoute(nameRoute) {
      this.$router.push({ name: nameRoute })
    }
  }
}
</script>
